// register the plugin on vue
import ABI from '@/assets/abi/exchange.json'
import store from '../store'
const Contract = require('web3-eth-contract');

export default class Exchange {
  constructor() {
    Contract.setProvider(store.state.rpcUrl);
    this.contract = new Contract(ABI, store.state.exchangeAddress);
  }

  async buy(amount){
    amount = amount * 10 ** 18
    const amountString = amount.toLocaleString('fullwide', {useGrouping:false})
    let extraData = await this.contract.methods.buy(amountString)
    let data = extraData.encodeABI()
    return this.sendTransaction(data)
  }

  async sell(amount){
    amount = amount * 10 ** 18
    const amountString = amount.toLocaleString('fullwide', {useGrouping:false})
    let extraData = await this.contract.methods.sell(amountString)
    let data = extraData.encodeABI()
    return this.sendTransaction(data)
  }

  async sendTransaction(data){
    const transactionParameters = {
      to: store.state.exchangeAddress,
      from: window.ethereum.selectedAddress,
      data: data,
    };
    
    try{
      let txHash = await window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters],
      })
      return {txHash: txHash}
    }catch(error){
      // console.log('error', error)
      return error
    }
  }
}