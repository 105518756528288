// register the plugin on vue
import ABI from '@/assets/abi/defi.json'
import store from '../store'
const Contract = require('web3-eth-contract');
// const Web3 = require("web3");

export default class Vault {
  constructor() {
    Contract.setProvider(store.state.rpcUrl);
    this.contract = new Contract(ABI, store.state.vaultAddress);
    // this.web3 = new Web3(new Web3.providers.HttpProvider(store.state.rpcUrl));
  }

  async getTokenAddress() {
    let egtAddress = await this.contract.methods.EGT().call();
    let usdtAddress = await this.contract.methods.USDT().call();
    return {egtAddress: egtAddress, usdtAddress: usdtAddress}
  }

  // Get isMember
  async getIsMember(){
    try{
      if (store.state.account) {
        let result = await this.contract.methods.Referrer(store.state.account).call();
        store.commit('updateIsMember', result !== '0x0000000000000000000000000000000000000000')
      } else {
        store.commit('updateIsMember', false)
      }
    }catch(error){
      console.log('error', error)
      store.commit('updateIsMember', false)
    }
  }

  async register(refererAddress){
    let extraData = await this.contract.methods.register(refererAddress)
    let data = extraData.encodeABI()
    return this.sendTransaction(data)
  }

  async deposit(depositToken, interestToken, amount){
    amount = amount * 10 ** 18
    const amountString = amount.toLocaleString('fullwide', {useGrouping:false})
    let extraData = await this.contract.methods.deposit(depositToken, interestToken, amountString)
    let data = extraData.encodeABI()
    return this.sendTransaction(data)
  }

  async redeem(index){
    let extraData = await this.contract.methods.claim(index)
    let data = extraData.encodeABI()
    return this.sendTransaction(data)
  }

  async claim(index){
    let extraData = await this.contract.methods.claimInterest(index)
    let data = extraData.encodeABI()
    return this.sendTransaction(data)
  }

  async sendTransaction(data){
    const transactionParameters = {
      to: store.state.vaultAddress,
      from: window.ethereum.selectedAddress,
      data: data,
    };
    
    try{
      let txHash = await window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters],
      })
      return {txHash: txHash}
    }catch(error){
      // console.log('error', error)
      return error
    }
  }
}