export default {
  data() {
    return {
      web3: null,
      // 表單驗證
      dataRules: (v) => !!v || 'Required',
      listRules: (v) => v.length !== 0 || 'Required',
      TokenAmountRules: [
        (v) => !!v || 'Required',
        (v) => /^[0-9]/.test(v) || 'Number Only',
        (v) => v > 0 || 'Cannot Be Negative',
      ],
      integerRule: (v) => Number.isInteger(parseFloat(v)) || 'Integer Only',
      accountRules: [
        (v) => !!v || 'Required',
        (v) => /0x[\w]{40}/.test(v) || 'Please Enter Correct Address',
        (v) => v.length === 42 || 'Please Enter Correct Address',
      ],
      voteRules: [
        (v) => v <= 300 || 'No More Than 300',
        (v) => v >= 10 || 'Must Higher Than 10',
      ],
      TokenAmountRulesNoRequired: [
        (v) => /^[0-9]/.test(v) || 'Number Only',
        (v) => v > 0 || 'Cannot Be Negative',
      ],
      rateRules: [
        (v) => v >= 0.1 || 'Must Higher Than 0.1%',
        (v) => v <= 100 || 'No More Than 100%',
      ],
      // assets orders
      orders: [],
      now: 0,
    };
  },
  methods: {
    timestampToDate(timestamp){
      let time = new Date(timestamp)
      let year = time.getFullYear()
      let month = time.getMonth() + 1
      let date = time.getDate()
      return `${year}-${month<10?`0${month}`:month}-${date<10?`0${date}`:date}`
    },
    timestampToTime(timestamp){
      let time = new Date(timestamp)
      let year = time.getFullYear()
      let month = time.getMonth() + 1
      let date = time.getDate()
      let hour = time.getHours()
      let min = time.getMinutes()
      let sec = time.getSeconds()

      return `${year}-${month<10?`0${month}`:month}-${date<10?`0${date}`:date} ${hour<10?`0${hour}`:hour}:${min<10?`0${min}`:min}:${sec<10?`0${sec}`:sec}`
    },


    // wallet
    async connectMetamask() {
      let _this = this
      if (window.ethereum){
        window.ethereum
          .request({ method: 'eth_requestAccounts' })
          .then(_this.handleAccountsChanged)
          .catch((err) => {
            if (err.code === 4001) {
              // EIP-1193 userRejectedRequest error
              // If this happens, the user rejected the connection request.
              this.$toasted.error('Please connect to MetaMask.');
            } else {
              console.error(err);
            }
          });
      }else{
        this.$toasted.error(this.$t('installMetamask'))
      }
    },
    async handleAccountsChanged(accounts){
      if (accounts.length === 0) {
        // MetaMask is locked or the user has not connected any accounts
        this.$toasted.error('Please connect to MetaMask.');
      } else if (accounts[0] !== this.$store.state.account) {
        this.$store.commit('updateAccount', accounts[0]);
        this.$cookies.set('address', accounts[0]);
        window.location.reload()
      }
    },
    checkChainId(chainId){
      let isEthereum = chainId === '0x1' || chainId === 1
      // let isEthereum = chainId === '0x4' || chainId === 4

      // let isPol = chainId === '0x89' || chainId === 137
      // let isPol = chainId === '0x13881' || chainId === 80001
      let isBsc
      if (this.$store.state.version === 'staging') {
        isBsc = chainId === '0x61' || chainId === 97
      } else {
        isBsc = chainId === '0x38' || chainId === 56
      }
      this.$store.commit('updateChainId', isBsc);
      this.$store.commit('updateIsEth', isEthereum);
      if (!isBsc){
        this.$toasted.error(this.$t('changeMainnet'));
      }
    },
    async getOrders() {
      let result = await this.$store.dispatch('getOrders')
      this.now = parseInt(Date.now() / 1000 / (60 * 60 * 24))
      if (result.status === 200) {
        result.data.forEach((item) => {
          item.totalTime = item.status ? Math.min(this.now - item.start_day, 30 * 12) : item.last_day - item.start_day
          item.hasClaim = item.last_day - item.start_day
          item.rate = (item.rate / 10 ** 18).toFixed(2)
        })

        this.orders = result.data
      } else {
        this.orders = []
        this.$toasted.error(this.$t('errorOccured'));
      }
    },
  },
};
