<template>
  <v-app class="app" dark>
    <v-app-bar
      class="app-bar px-2"
      app
      dark
      color="darkGrey"
      height="60"
    >
      <div class="d-flex align-center can-click" @click="()=>{if ($route.name!=='Home'){ $router.push({name: 'Home'})}}">
        <v-img
          class="mr-2"
          alt="vault-Logo"
          src="@/assets/img/logo.png"
        />
        <div class="lightPrimary2--text font-w-4">
          <div class="small-rem-9 mb-n1">VAULT</div>
          <div class="rem-2">{{ $t('vaultName') }}</div>
        </div>
      </div>

      <v-spacer></v-spacer>

      <div class="d-flex align-center">
        <v-list class="pa-0 transparent d-sm-block d-none" height="52px" v-if="$store.state.account">
          <v-btn
            v-for="(item, i) in footerItems.filter(item => item.text !== 'index')"
            :key="i"
            class="px-3"
            height="100%"
            color="transparent"
            depressed
            tile
            link
            exact
            active-class="nav-avtive"
            :to="item.routerName === 'Deposit' ? {name: 'Deposit', params: {lang: $store.state.locale, interestToken: $store.state.interestTokens[0].token, depositToken: $store.state.depositTokens[0].token}}
               : item.routerName === 'Asset' ? {name: 'Asset', params: {lang: $store.state.locale, interestToken: $store.state.interestTokens[0].token}}
               : {name: item.routerName, params: {lang: $store.state.locale}}
            "
          >{{ $t(`footer${item.text.charAt(0).toUpperCase() + item.text.slice(1)}`) }}</v-btn>
        </v-list>
        <btn class="no-padding-btn" isText :buttonText="$store.state.account? `${$store.state.nowWidth>960?`${shortAddress} ${$t('logout')}`:`${$t('logout')}`}` : `${$t('login')}`" :color="'lightPrimary2'" @clickBtn="log()"></btn>
        <v-menu
          open-on-hover
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <span class="no-padding-btn">
              <v-btn
                class="px-0"
                color="white"
                v-bind="attrs"
                v-on="on"
                text
              >
                <img src="@/assets/img/icon-translate.svg" alt="">
              </v-btn>
            </span>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, index) in $store.state.langs"
              :key="index"
              class="can-click"
              @click="changeLang(item)"
            >
              <v-list-item-title>{{ $t(item) }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>

    <v-main id="main" class="mt-15 pb-16 px-3 py-2">
      <router-view />
    </v-main>

    <v-footer
      fixed
      id="footer"
      class="px-4 pt-2 d-flex d-sm-none justify-space-between lightPrimary2--text"
    >
      <!--<img src="@/assets/img/footer-wrap.svg" class="footer-bg mx-auto">-->
      <div class="d-flex flex-column justify-center align-center can-click" v-for="(item, i) in footerItems" :key="i" @click="toPage(item.routerName)">
        <div v-if="item.text !== 'index'" class="d-flex flex-column justify-center align-center font-w-4 small-rem-9">
          <img :src="`${require(`@/assets/img/icon-${item.text}.svg`)}`" :alt="item.text" width="20px" class="mb-1">
          <div>{{ $t(`footer${item.text.charAt(0).toUpperCase() + item.text.slice(1)}`) }}</div>
        </div>
        <img
          v-else
          alt="index"
          src="@/assets/img/icon-index.svg"
          width="34px"
          class="can-click"
        />
        <img src="@/assets/img/footer-pointer.svg" class="footer-pointer" v-if="$route.name === item.routerName">
      </div>
    </v-footer>

    <v-dialog max-width="310px" v-model="$store.state.dialogShow.isShow">
      <!-- register -->
      <v-card
        v-if="$store.state.dialogShow.type === 'register'"
        class="pa-3"
        color="white"
      >
        <div class="d-flex justify-end can-click" @click="closeDialog()"><img src="@/assets/img/icon-close.svg" alt=""></div>
        <div class="d-flex flex-column align-center">
          <h2 class="darkGrey--text text-center rem-12 font-w-7" style="letter-spacing: 0.125em;">{{ $t('activateService') }}</h2>
          <img src="@/assets/img/img-register.png" alt="">
          <div class="lightGrey2--text font-w-4 rem-1 mb-3">{{ $t('fillActivateAddress') }}</div>
          <v-form ref="registerForm" lazy-validation>
            <v-text-field
              class="mb-3"
              fill
              solo
              flat
              light
              dense
              hide-details="auto"
              v-model="refererAddress"
              background-color="lightGrey1"
              :rules="accountRules"
            ></v-text-field>
          </v-form>
          <template v-if="$store.state.account">
            <div class="primary--text rem-0 font-w-4 mb-1">{{ $t('currLoginAddress') }}</div>
            <div class="success white--text rem-0 font-w-4 py-1 px-3 border-radius-3 mx-auto mb-4 can-click" @click="refererAddress = $store.state.account">{{ $t('autoFill') }}</div>
          </template>
          <v-btn color="lightPrimary2" width="100%" height="38" class="rem-6 mb-2" light depressed @click="register()">{{ $t('activateConfirm') }}</v-btn>
        </div>
      </v-card>
      <!-- redeem & claim interest -->
      <v-card
        v-if="$store.state.dialogShow.type === 'redeem' || $store.state.dialogShow.type === 'claim'"
        class="pa-3"
        :color="$store.state.dialogShow.type === 'claim' ? 'white' : '#FFDADE'"
      >
        <div class="d-flex justify-end can-click" @click="closeDialog()"><img src="@/assets/img/icon-close.svg" alt=""></div>
        <div class="d-flex flex-column align-center">
          <h2 class="darkGrey--text text-center rem-12 font-w-7" style="letter-spacing: 0.125em;">{{ $t($store.state.dialogShow.type === 'redeem' ? 'redeemDepositToken' : 'claimInterest') }}</h2>
          <img :src="require(`@/assets/img/img-${$store.state.dialogShow.type}.png`)" alt="">
          <v-text-field
            class="mb-4 text-center"
            fill
            solo
            flat
            light
            dense
            readonly
            hide-details
            :value="$store.state.dialogShow.type === 'claim' ? `${$store.state.dialogShow.data.payment_amount} ${$route.params.interestToken.toUpperCase()}` : `${$store.state.dialogShow.data.amount} ${$store.state.dialogShow.data.token_name}`"
            :background-color="$store.state.dialogShow.type === 'claim' ? 'lightGrey1' : '#FFADAD'"
          ></v-text-field>
          <div class="mb-2 black--text rem-0 text-center font-w-4">{{ $t(`${$store.state.dialogShow.type}Description`) }}</div>
          <v-btn :color="$store.state.dialogShow.type === 'claim' ? 'lightPrimary2' : '#C14646'" width="100%" height="38" class="rem-6 mb-2" :light="$store.state.dialogShow.type === 'claim'" depressed @click="confirm()">{{ $t($store.state.dialogShow.type) }}</v-btn>
        </div>
      </v-card>
    </v-dialog>

    <loading :loadingShow="$store.state.loading.isShow" :content="$store.state.loading.text"></loading>

    <v-dialog persistent v-model="$store.state.locationWarning" :max-width="locationWarningDetails ? 691 : 435" width="95%" light>
      <v-card class="pt-11 pb-7" v-if="!locationWarningDetails">
        <div class="rem-20 font-weight-black text-center px-5 mb-10 pre-wrap" style="color: #4F4F4F;">{{ $t('notForTaiwan') }}</div>
        <v-btn class="mx-auto d-block mb-3" color="#C18824" min-width="150" depressed dark style="border-radius: 25px" @click="locationWarningDetails=true">{{ $t('toDetails') }}</v-btn>
        <v-btn class="mx-auto d-block" color="#878788" min-width="150" depressed dark style="border-radius: 25px" @click="$store.commit('updateLocationWarning', false)">{{ $t('close') }}</v-btn>
      </v-card>
      <v-card class="pt-md-11 pt-7 pb-md-7 pb-4 px-md-11 px-4" v-else>
        <div class="rem-20 font-weight-black text-center mb-md-11 mb-4" style="color: #C18824;">{{ $t('notForTaiwan') }}</div>
        <div class="rem-8 mb-md-8 mb-4 pre-wrap" style="color: #818181;">{{ $t('notForTaiwanContent') }}</div>
        <v-btn class="mx-auto d-block mb-3" color="#00A77B" min-width="150" depressed dark style="border-radius: 25px" @click="$store.commit('updateLocationWarning', false)">{{ $t('close') }}</v-btn>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import btn from '@/components/btn.vue';
import loading from '@/components/loading.vue'
import base from '@/mixin/base'
export default {
  name: 'App',
  mixins: [base],
  data: () => ({
    footerItems: [
      {
        text: 'deposit',
        routerName: 'Deposit',
      },
      {
        text: 'swap',
        routerName: 'Swap',
      },
      {
        text: 'index',
        routerName: 'Home',
      },
      {
        text: 'rule',
        routerName: 'Rule',
      },
      {
        text: 'asset',
        routerName: 'Asset',
      },
    ],
    refererAddress: '',
    timer: null,
    locationWarningDetails: false,
  }),
  components:{
    btn,
    loading
  },
  computed:{
    shortAddress(){
      return `${this.$store.state.account.slice(0, 6)}...${this.$store.state.account.slice(38)}`
    }
  },
  methods:{
    async log(){
      if (this.$store.state.account){
        this.$store.commit('clearInfo')
        this.$cookies.remove('address')
        if (this.$route.name === 'Home') {
          window.location.reload()
        } else {
          this.$router.push({name: 'Home'})
        }
      }else{
        if (window.ethereum) {
          await this.connectMetamask()
        } else {
          window.addEventListener('ethereum#initialized', this.connectMetamask, {
            once: true,
          });

          // If the event is not dispatched by the end of the timeout,
          // the user probably doesn't have MetaMask installed.
          setTimeout(this.connectMetamask, 2000); // 3 seconds
        }
        this.$forceUpdate()
      }
    },
    changeLang(lang){
      this.$store.commit('updateLang', lang)
      this.$i18n.locale = lang
      this.$router.push({ name: this.$route.name, params: {lang: lang, token: this.$route.params.token} });
    },
    toPage(name) {
      if (this.$route.name !== name) {
        if (name === 'Deposit') {
          this.$router.push({name: 'Deposit', params: {lang: this.$store.state.locale, interestToken: this.$store.state.interestTokens[0].token, depositToken: this.$store.state.depositTokens[0].token}})
        } else if (name === 'Asset') {
          this.$router.push({name: name, params: {lang: this.$store.state.locale, interestToken: this.$store.state.interestTokens[0].token}})
        } else {
          this.$router.push({name: name, params: {lang: this.$store.state.locale}})
        }
      }
    },
    async register() {
      if (this.$store.state.chainId){
        if (this.$refs.registerForm.validate()){
          if (this.$store.state.isMember){
            this.$toasted.error(this.$t('addressHasUsed'))
          }else{
            let result = await this.$vault.register(this.refererAddress)
            // console.log('result', result)
            if (result.txHash){
              this.$store.commit('updateLoading', {isShow: true, text: this.$t('waitActivate')})
              this.timer = window.setInterval(async () => {
                await this.$vault.getIsMember()
                if (this.$store.state.isMember) {
                  window.clearInterval(this.timer)
                  this.$store.commit('updateLoading', {isShow: false, text: ''})
                  this.closeDialog()
                }
              }, 1000)
            }else if (result.code === 4001){
              this.$toasted.error(this.$t('userRefuse'))
            }
          }
        }
      }else{
        this.$toasted.error(this.$t('changeMainnet'))
      }
    },
    confirm() {
      if (this.$store.state.dialogShow.type === 'redeem') {
        this.redeem(this.$store.state.dialogShow.data)
      } else {
        this.claim(this.$store.state.dialogShow.data)
      }
    },
    async redeem(item) {
      if (item.status) {
        if (this.$store.state.chainId){
          let result = await this.$vault.redeem(item.id)
          // console.log('result', result)
          if (result.txHash){
            this.claimLoading()
          }else if (result.code === 4001){
            this.$toasted.error(this.$t('userRefuse'))
          }
        }else{
          this.$toasted.error(this.$t('changeMainnet'))
        }
      }
    },
    async claim(item) {
      if (item.status && item.payment_amount !== '0') {
        if (this.$store.state.chainId){
          let result = await this.$vault.claim(item.id)
          // console.log('result', result)
          if (result.txHash){
            this.claimLoading()
          }else if (result.code === 4001){
            this.$toasted.error(this.$t('userRefuse'))
          }
        }else{
          this.$toasted.error(this.$t('changeMainnet'))
        }
      }
    },
    async claimLoading() {
      this.$store.commit('updateLoading', {isShow: true, text: this.$t('waitClaim')})
      const hasClaim = this.$store.state.dialogShow.data.hasClaim
      const status = this.$store.state.dialogShow.data.status
      const currIndex = this.$store.state.dialogShow.currIndex

      this.timer = window.setInterval(async () => {
        await this.getOrders()
        if ((this.orders[currIndex].hasClaim !== hasClaim) || (this.orders[currIndex].status !== status)) {
          window.clearInterval(this.timer)
          window.location.reload()
          this.closeDialog()
          this.$forceUpdate()
          this.$store.commit('updateLoading', {isShow: false, text: ''})
        }
      }, 1000)
    },
    closeDialog() {
      this.$store.commit('updateDialog', {isShow: false, type: ''})
      this.refererAddress = ''
    }
  },
  async mounted(){
    // console.log('==========default==========')
    let _this = this

    this.$store.commit('updateNowWidth', document.body.clientWidth)
    window.onresize = () => {
      this.$store.commit('updateNowWidth', document.body.clientWidth)
    }

    if (window.ethereum){
      // metamask disconnect
      window.ethereum.on('accountsChanged', (accounts) => {
        if (accounts.length === 0){
          this.$cookies.remove('address')
          this.$store.commit('clearInfo')
          this.$toasted.error(_this.$t('connectionBreak'))
        }else{
          this.$store.commit('updateAccount', accounts[0]);
          this.$cookies.set('address', accounts[0]);
          this.$toasted.show(_this.$t('changeWallet'));
          window.location.reload()
        }
      });

      // get chain id
      const chainId = await window.ethereum.request({ method: 'eth_chainId' });
      await this.$store.dispatch('getContractAddress')
      this.checkChainId(chainId)
      window.ethereum.on('chainChanged', _this.checkChainId);

      if (!window.ethereum.selectedAddress){
        let address = this.$cookies.get('address')
        if (address){
          this.$cookies.remove('address')
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import '../src/assets/scss/font.scss';
@import url('https://fonts.googleapis.com/css2?family=Share+Tech&family=Tauri&display=swap');
// layout
.app{
  cursor: default;
  overflow: hidden;
}
#main{
  background: url('../src/assets/img/img-bg.svg') right 60px/cover no-repeat,
              linear-gradient(180deg, #000000 5%, #dddddd80 50%, #dddddd80 60%, rgba(0, 0, 0, 0) 94.92%);
  background-blend-mode: multiply;
  @include dai_vuetify_sm {
    background: url('../src/assets/img/img-bg.svg') right 60px/cover no-repeat,
                #313131;
  }
}
.app-bar{
  box-shadow: 0px 3.5px 3.5px rgba(7, 15, 28, 0.25);
  .no-padding-btn{
    .v-btn:not(.v-btn--round).v-size--default{
      @include dai_vuetify_sm{
        min-width: 40px;
        padding: 0 10px;
      }
    }
  }
  
  .nav-avtive {
    background: linear-gradient(360deg, rgba(240, 205, 143, 0.3) 0%, transparent 100%) !important;
    position: relative;
    height: calc(100% + 4px) !important;
    &:before {
      background-color: rgba(0, 0, 0, 0) !important;
    }
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      background: var(--v-lightPrimary2-base);
    }
  }
}
#footer {
  min-height: 60px;
  border-radius: 6px;
  border: #F0CD8F 1px solid;
  width: 95%;
  margin: 0 auto 20px;
  img.footer-pointer {
    position: absolute;
    bottom: -1px;
  }
}

// common style
.font-tauri {
  font-family: 'Tauri' !important;
}
.font-share-tech {
  font-family: 'Share Tech' !important;
}
.pre-wrap {
  white-space: pre-wrap;
}
.can-click{
  cursor: pointer;
}
.bg-grey-radial-gradient {
  background: radial-gradient(86.09% 216.62% at 8.7% 11.02%, #636363 0%, #232324 100%);
}
.bg-primary-radial-gradient {
  background: radial-gradient(78.7% 198.02% at 8.7% 11.02%, #F0CD8F 0%, #C0914E 87.86%);
}
.border-radius-3{
  border-radius: 3px;
}
.toasted.toasted-primary.error{
  background-color: var(--v-error-base) !important;
}
.toasted.toasted-primary.default{
  background-color: var(--v-primary-base) !important;
}
</style>