// register the plugin on vue
import ABI from '@/assets/abi/usdt.json'
import store from '../store'

const Contract = require('web3-eth-contract');

export default class Token {
  constructor(tokenAddress) {
    Contract.setProvider(store.state.rpcUrl);
    this.tokenAddress = tokenAddress
    this.contract = new Contract(ABI, tokenAddress);
    // console.log('this.contract', this.contract)
  }

  async getBalance() {
    try{
      let balance = await this.contract.methods.balanceOf(store.state.account).call();
      return parseFloat((parseInt(balance) / (10 ** 18)).toFixed(5));
    }catch(error){
      console.log('error', error)
    }
  }

  async getAllowance(defiAddress){
    let allowance = await this.contract.methods.allowance(store.state.account, defiAddress).call();
    return parseInt(allowance) / (10 ** 18)
  }

  async approve(defiAddress){
    const num = 1000000000 * 10 ** 18;
    const numString = num.toLocaleString('fullwide', {useGrouping:false})

    let extraData =  await this.contract.methods.approve(defiAddress, numString)
    let data = extraData.encodeABI()
    return this.sendTransaction(data)
  }

  async sendTransaction(data){
    const transactionParameters = {
      to: this.tokenAddress,
      from: window.ethereum.selectedAddress,
      data: data,
    };
    
    try{
      let txHash = await window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters],
      })
      return {txHash: txHash}
    }catch(error){
      // console.log('error', error)
      return error
    }
  }
}