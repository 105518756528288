export default {
  test: '測試測試測試',
  // langs
  tw: '繁體中文',
  jp: '日本語',
  en: 'English',
  cn: '简体中文',
  // navbar
  vaultName: '鋤金靈活存摺',
  // home
  login: '登入',
  logout: '登出',
  indexSubtitle: 'VAULT 透過智能合約活存您的加密資產 穩定收益',
  indexTitle: '存幣鋤金 就這麼簡單',
  depositImmediately: '立即存幣',
  // footer
  footerDeposit: '我要存幣',
  footerSwap: '我要兌幣',
  footerRule: '鋤金規則',
  footerAsset: '我的資產',
  // swap
  tokenSwap: '幣種兌換',
  // rule
  ruleWarning: '以下操作之BNB礦工費須投資人自行負擔',
  ruleContent: [
    '投資前須先將幣種存放於區塊鏈錢包中於「存幣清單」中選擇並申請存幣',
    '成功存幣後幣種會自動存入智能合約當中，並於「我的資產」中看到該存幣合約',
    '該存幣合約可隨時領出本金，存滿每個月都會計算利息，以累積存幣月份，將提高該月份利率計算，未滿當月即贖回存幣，將無計算當月利息，若存滿12個月，將不會計算利率，請務必贖回再另外存幣。',
    '存幣最低數量為 1 EGT，且必須為整數。贖回/領取後，智能合約會轉入存幣用戶區塊鏈錢包中。',
    '【利率為浮動預估利率，將以系統呈現為主，保有最終解釋權】'
  ],
  // deposit
  depositAmount: '存幣金額',
  enterDepositTokenAmount: '輸入 {token} 數量',
  max: '最大',
  tokenAvailable: '可用數量 {amount} {token}',
  detailedInfo: '詳細資訊',
  profitDescription: '此存幣合約收益為 {token}',
  depositDate: '存幣日期',
  startInterestDate: '起息日',
  distributeInterestDate: '發息日期',
  monthExpectedRate: '月存預估費率',
  showMore: '顯示更多',
  showLess: '收起',
  firstMonth: '首月',
  nthMonth: '第 {month} 個月',
  agreeRule: '我已熟悉規則並閱讀且同意 <a target="_blank" href="/{lang}/rule">鋤金服務協議</a>',
  depositDescription: '靈活彈性 讓您可以隨時贖回您的加密貨幣',
  // assets
  orderId: '編號：{id}',
  orderTime: '存幣時間 {date}天',
  orderRate: '存幣時匯率 1 {depositToken}：{value} {interestToken}',
  redeemDepositToken: '贖回存幣',
  redeemDescription: '結束此筆存幣合約，並且贖回金額',
  redeem: '確認贖回',
  claimInterest: '領取利息',
  claimDescription: '領取當前累積的存幣合約利息',
  claim: '確認領取',
  // other
  yourAddress: '您的收款地址',
  backToIndex: '返回首頁',
  backToPreviousPage: '回前頁',
  lock: '未啟用',
  unlock: '已啟用',
  approve: '申請前請先解鎖',
  noRecord: '無相關紀錄',
  activateService: '啟用您的服務',
  fillActivateAddress: '請填寫啟用的地址',
  currLoginAddress: '您目前登入地址',
  autoFill: '自動填入',
  activateConfirm: '確認啟用',
  // toasted
  loginFirst: '請先登入',
  installMetamask: '請安裝 MetaMask',
  changeMainnet: '請切換到幣安智能鏈',
  changeETH: '請切換至以太主網',
  connectionBreak: 'MetaMask 連接中斷，請重新登入',
  changeWallet: '已切換連接錢包',
  waitLoading: '讀取中，請稍後',
  waitApprove: '解鎖中，請稍後',
  waitActivate: '啟用中，請稍後',
  waitClaim: '提取中，請稍後',
  txSend: '交易已送出',
  userRefuse: '使用者拒絕連線',
  addressHasUsed: '此地址已註冊',
  errorOccured: '發生錯誤',
  USDTUnderBalance: 'USDT 餘額不足',
  TBTUnderBalance: 'TBT 餘額不足',
  underBalance: '餘額不足',
  gameOpening: '彩金池開獎中',
  cannotGetGas: '無法計算礦工費',
  renewGetGas: 'WebSocket 連線中斷，請重整網頁以獲取最新的預估礦工費用',
  renew: 'WebSocket 連線中斷，請重整網頁',
  waitGetData: '正在從鏈上獲取資訊，請稍後',
  noReward: '尚無收益可領取',
  cannotGetEthValue: '取得 ETH 市值失敗',
  cannotGetData: '取得資料失敗',
  selectTx: '請選擇要補助之交易',
  txError: '交易發生錯誤',
  agreeFirst: '請勾選同意',
  cannotGetMarketValue: '無法計算市值',
  isNotWhitelist: '權限不足，請聯絡管理員',
  notForTaiwan: '本站\n不服務台灣地區客戶',
  toDetails: '前往詳細說明',
  close: '關閉',
  notForTaiwanContent: `本公司一直致力於提供高品質的產品和服務，並在全球各地區建立了廣泛的客戶群。\n\n然而，由於一些法律和管理上的限制，本公司目前無法向台灣地區用戶提供產品和服務\n\n我們非常遺憾這樣的決定可能對您帶來不便和困擾。感謝您對本公司的支持和信任，並感謝您的理解。\n\n如果您有任何疑問或需要進一步的解釋，請隨時聯繫我們的客戶服務部門。我們將盡最大努力為您提供支持和協助。\n\n再次感謝您的支持和理解，我們期待有機會為您提供服務。\n\n`,
  waitForChainData: `等待鏈上抓取資料`,
  depositNowIfHavent: `若尚未存幣，請點選立即存幣`
}