import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import app from '../main'
import Toasted from 'vue-toasted';

// contract
import Vault from '../plugins/defi'
import Exchange from '../plugins/exchange'
import Token from '../plugins/token'

// import jwt_decode from "jwt-decode";
const cookies = require('vue-cookies')
Vue.use(VueRouter)
Vue.use(cookies);
Vue.use(Toasted, {
  // router,
  position: 'top-center',
  duration: 2000,
});

const routes = [
  {
    path: '/:lang',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/:lang/swap',
    name: 'Swap',
    component: () => import('../views/Swap.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/:lang/rule',
    name: 'Rule',
    component: () => import('../views/Rule.vue'),
    meta: { requiresAuth: false },
  },
  // deposit related
  {
    path: '/:lang/:interestToken',
    name: 'Asset',
    component: () => import('../views/_interestToken/Asset.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/:lang/:interestToken/:depositToken/:orderId',
    name: 'Order',
    component: () => import('../views/_interestToken/_depositToken/_orderId.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/:lang/:interestToken/:depositToken',
    name: 'Deposit',
    component: () => import('../views/_interestToken/_depositToken/Deposit.vue'),
    meta: { requiresAuth: true },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  // console.log('to.params.lang', to.params.lang)
  if (!to.params.lang || !store.state.langs.includes(to.params.lang)){
    next('/tw')
  }

  // Get chain data if no data
  try {
    await store.dispatch('getRpcUrl')
  }catch(error) {
    store.commit('updateRpcUrl', 'https://rpc-mumbai.matic.today')
    console.log('error', error)
  }

  if (!store.state.vaultAddress || !store.state.exchangeAddress){
    try {
      await store.dispatch('getContractAddress')
      Vue.prototype.$vault = new Vault();
      let {egtAddress, usdtAddress} = await Vue.prototype.$vault.getTokenAddress()

      Vue.prototype.$exchange = new Exchange();
      Vue.prototype.$token = {
        egt: new Token(egtAddress),
        usdt: new Token(usdtAddress),
        eth: new Token(store.state.ethAddress),
      };
    }catch(error) {
      console.log('error', error)
    }
  }

  // Get other data if no data
  if (!store.state.interestTokens.length || !store.state.depositTokens.length) {
    try{
      await store.dispatch('getVaultTokenPair')
    }catch(error){
      console.log('error', error)
    }
  }

  // Get user info from cookies
  try{
    let address = Vue.$cookies.get('address')
    if (address != undefined) {
      if ( store.state.account === '' ){
        try{
          store.commit('updateAccount', address)
          await store.dispatch('getIsWhitelist')
          await Vue.prototype.$vault.getIsMember()
        }catch(error){
          console.log('error', error)
        }
      }
    }
  }catch(error){
    console.log('error', error)
    next();
  }

  // 如果 router 轉跳的頁面需要驗證 requiresAuth: true
  if (to.matched.some(record => {
    // console.log(record.name, record.meta.requiresAuth);
    return record.meta.requiresAuth;
  })) {
    // 未登入
    if (store.state.account === ''){
      try{
        if (from.name !== 'Home') next({ name: 'Home', params: {lang: from.params.lang} });
        Vue.toasted.error(app.$i18n.messages[from.params.lang]['loginFirst'])
      }catch(error){
        console.log('error', error)
      }
    // 權限不足
    } else if (!store.state.isWhitelist) {
      if (from.name !== 'Home') next({ name: 'Home', params: {lang: from.params.lang} });
      Vue.toasted.error(app.$i18n.messages[from.params.lang]['isNotWhitelist'])
    // 未註冊
    } else if (!store.state.isMember) {
      if (from.name !== 'Home') next({ name: 'Home', params: {lang: from.params.lang} });
      store.commit('updateDialog', {isShow: true, type: 'register'})
    } else {
      next(); // 往下繼續執行
    }
  } else {
    next(); // 往下繼續執行
  }
});

router.afterEach(async (to) => {
  // Get lang from route path
  if (to.params.lang){
    store.commit('updateLang', to.params.lang)
    app.$i18n.locale = to.params.lang
  }
});


export default router
